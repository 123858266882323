<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="data_effetto_dal"
                label="Data di effetto dal"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="data_effetto_al"
                label="Data di effetto al"
                v-model="filter.byCalendar.to"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Produttore"
                label="Produttore"
                v-model="filter.byBroker.id"
                :options="salesmen"
                :taggable="salesmen_taggable"
                :multiple="true"
                :closeOnSelect="false"
                :defaultHtmlMultipleSelect="true"
                @changeTaggable="changeTaggable"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Compagnia"
                label="Compagnia"
                :options="companies"
                v-model="filter.byInsurer.id"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Stato Titoli"
                label="Stato Titoli"
                v-model="filter.byAttribute.status_ancillary"
                :options="titoli"
                :taggable="true"
                :multiple="true"
                :closeOnSelect="false"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="tipo_titoli"
                label="Tipo Titoli"
                v-model="filter.byAttribute.code"
                :options="tipo_titoli"
                :taggable="true"
                :multiple="true"
                :closeOnSelect="false"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="risks"
                label="Tipologie di polizze"
                :options="risks"
                v-model="filter.byRiskType.id"
                :taggable="true"
                :multiple="true"
                :closeOnSelect="false"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="coopertaor"
                label="Collaborazione"
                :options="cooperators"
                v-model="filter.byCollaborator.id"
                :taggable="true"
                :multiple="true"
                :closeOnSelect="false"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Anagrafica"
                      label="Anagrafica"
                      v-model="form.inpt_label_registry"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchRegistry"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_registry"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetRegistryId"
                      title="Elimina Anagrafica"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-registry-modal
              ref="quickSearchRegistry"
              @input="handleRegistryModalInput"
            >
            </quick-search-registry-modal>
            <div class="form-group col-md-3">
              <span
                v-if="registry_data"
                class="info"
                v-html="toInfoData(registry_data, 'registry')"
              >
              </span>
            </div>
            <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Polizza"
                      label="Polizza"
                      v-model="form.inpt_label_policy"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchPolicy"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_policy"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetPolicyId"
                      title="Elimina Polizza"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-policy-modal
              ref="quickSearchPolicy"
              @input="handlePolicyModalInput"
            ></quick-search-policy-modal>
            <!-- <div class="form-group col-md-3">
              <span
                v-if="insurance_policy_data"
                class="info"
                v-html="
                  toInfoData(insurance_policy_data, 'insurance_policy', 0)
                "
              >
              </span>
            </div> -->
          </b-row>
          <b-row>
            <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Gruppo Anagrafico"
                      label="Gruppo Anagrafico"
                      v-model="form.inpt_label_group"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchGroup"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_group"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetGroupId"
                      title="Elimina Gruppo"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-group-modal
              ref="quickSearchGroup"
              @input="handleGroupModalInput"
            ></quick-search-group-modal>
            <div class="form-group col-md-3">
              <span
                v-if="group_data"
                class="info"
                v-html="toInfoData(group_data, 'registry_group_full')"
              >
              </span>
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="effective_at"
                label="Data di riferimento per titoli arretrati"
                v-model="filter.byStocastico.date"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="status"
                label="Condizione titoli"
                v-model="filter.byStocastico.status"
                :options="opt_status"
                :taggable="true"
                :multiple="true"
                :closeOnSelect="false"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-input
                name="commento_qt"
                v-model="filter.byInsurancePayment.comment"
                type="text"
                label="Commento QT"
                placeholder="Inserisci un commento"
              />
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        // {
        //   code: 'LISTIT',
        //   label: null,
        //   formats: ['csv', 'pdf'],
        // },
        {
          code: 'LISTITINCA',
          label: null,
          formats: ['csv', 'pdf'],
        },
        {
          code: 'EXPOINAN',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>
    <!-- <button-group-table
      @csv="onExport('csv')"
      :options="{
        CSV: { enabled: true },
      }"
    ></button-group-table> -->

    <insurance-ancillaries
      @edit="onEdit"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterOn="{
        byRelations: [
          'byTask',
          'byBroker',
          'byRegistryGroup',
          'byBookEntry',
          'byRegistry',
          'byCollaborator',
          'byInsurerParticipant',
        ],
      }"
      :filterName="filterName"
      :onlyActions="['edit', 'infomodal']"
      :ref="tableRef"
      @rowSelector="onAddBookEntry"
      includeRowSelector
      sortField="delay_until"
    ></insurance-ancillaries>

    <b-row class="table-extra-footer">
      <div class="ending_colour"></div>
      <p class="label">in scadenza</p>
      <div class="last_day_colour"></div>
      <p class="label">ultimo giorno</p>
      <div class="expired_colour"></div>
      <p class="label">scaduti</p>
    </b-row>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import InsuranceAncillaries from "@/components/tables/InsuranceAncillaries";
import BaseInput from "@/components/form/BaseInput";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import ExportOptions from "@/components/ExportOptions";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import QuickSearchPolicyModal from "@/components/modals/quickSearchPolicy";
import QuickSearchGroupModal from "@/components/modals/quickSearchGroup";
import { toLocaleCurrency } from "@/utils/strings";
import { toInfoData } from "@/utils/transforms";
import { toLocaleDate } from "@/utils/dates";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
moment.locale("it");
// import ExportsMixin from "@/mixins/ExportsMixin";

export default {
  mixins: [CustomFiltersMixin, StorageGetterMixin],
  data() {
    return {
      exportUrl: null,
      filter: this.initFilter(),
      companies: [],
      status_policy_opt: [
        { value: "0", text: "Non perfezionata" },
        { value: "1", text: "Perfezionata" },
        { value: "2", text: "Sospesa" },
        { value: "3", text: "Sostituita" },
        { value: "10", text: "Stornata" },
        { value: "4", text: "Annullata" },
        { value: "5", text: "Scaduta" },
        { value: "6", text: "Svincolata" },
        { value: "7", text: "Abbandonata" },
        { value: "8", text: "Disdetta" },
        { value: "9", text: "Nuova viva" },
      ],
      tableRef: "InsuranceAncillariesTableRef",
      filterName: "filterInsuranceAncillaries",
      resource: "insurance_ancillaries",
      repository: "insurance_ancillary",
      registry_data: null,
      insurance_policy_data: null,
      group_data: null,
      risks: [],
      notes: [],
      form: {
        inpt_label_policy: "",
        inpt_label_registry: "",
        inpt_label_group: "",
        quick_value: "",
      },
      fields: [
        {
          key: "rowSelector",
          label: "Incassa",
          sortable: false,
          thStyle: {
            width: "20px",
          },
        },
        {
          key: "effective_at",
          label: this.getDictionary("effective_at", "insurance_ancillary"),
          sortable: true,
          sortKey: "byAttribute.effective_at",
          stickyColumn: true,
          formatter: (value) => toLocaleDate(value),
          thStyle: {
            width: "80px",
          },
        },
        {
          key: "delay_until",
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          label: this.getDictionary("delay_until", "insurance_ancillary"),
        },
        {
          key: "insurance_policy_insurer",
          label: this.getDictionary(
            "insurance_policy_id",
            "insurance_ancillary"
          ),
          sortable: true,
          sortKey: "byInsurancePolicy.number",
        },
        {
          key: "cooperators",
          label: this.getDictionary("cooperators", "insurance_ancillary"),
        },
        {
          key: "code.value",
          label: this.getDictionary("code", "insurance_ancillary"),
          sortKey: "code",
          sortable: true,
        },
        {
          key: "insurance_policy.insurance_risk.risk_branch.formatted_title",
          label: this.getDictionary("title", "risk_branch"),
          sortable: true,
          sortKey: "byRiskBranch.title",
        },
        {
          key: "insurance_policy.title",
          label: this.getDictionary("Descrizione Polizza"),
          sortable: true,
          sortKey: "byInsurancePolicy.title",
        },
        {
          key: "customer",
          label: this.getDictionary("contractor"),
          sortable: true,
          sortKey: "byRegistry.title",
        },
        {
          key: "address_book",
          label: this.getDictionary("address_book"),
          sortable: false,
        },
        {
          key: "primary_group",
          label: this.getDictionary("primary_group"),
          sortable: true,
          sortKey: "byRegistryGroup.code",
        },
        {
          key: "gross",
          label: this.getDictionary("gross", "insurance_ancillary"),
          formatter: (value) => toLocaleCurrency(value),
          sortable: true,
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "status_ancillary.text",
          label: this.getDictionary("status_ancillary", "insurance_ancillary"),
          sortable: true,
          sortKey: "status_ancillary",
        },
        {
          key: "delay_days",
          label: this.getDictionary("delay_days", "insurance_ancillary"),
          sortable: false,
        },
        {
          key: "broker",
          label: this.getDictionary("broker_id", "insurance_ancillary"),
          sortable: true,
          sortKey: "byBroker.BUID",
        },
        {
          key: "insurance_payments",
          label: "Commento QT",
          sortable: false,
          formatter: (value) => this.retrieveComment(value),
        },
        {
          key: "note_counter",
          label: this.getDictionary("Note"),
          sortable: false,
        },
      ],
      salesmen: [],
      salesmen_taggable: true,
      cooperators: [],
      titoli: [
        { value: "0", text: "Da incassare" },
        { value: "1", text: "Incassato" },
        { value: "2", text: "Stornati" },
        { value: "3", text: "Annullati manualmente" },
        { value: "4", text: "Annullati manualmente + annullo polizza" },
        { value: "5", text: "Annullati su piattaforma compagnia" },
        { value: "6", text: "Annullati per sostituzione" },
        { value: "7", text: "Da validare" },
      ],
      tipo_titoli: [
        { value: "P", text: "Polizze" },
        { value: "Q", text: "Quietanze" },
        { value: "A", text: "Appendici" },
        // { value: "X", text: "Pregresso" },
      ],
      opt_status: [
        { value: "expired", text: "Scaduti" },
        { value: "last_day", text: "Ultimo giorno di mora" },
        { value: "ending", text: "In scadenza" },
        { value: "valid", text: "Validi" },
      ],
    };
  },
  components: {
    BaseInput,
    // ButtonGroupTable,
    ExportOptions,
    BaseSelect,
    BaseDatepicker,
    InsuranceAncillaries,
    QuickSearchRegistryModal,
    QuickSearchPolicyModal,
    QuickSearchGroupModal,
  },
  methods: {
    toInfoData,
    retrieveComment(value) {
      return value?.[0]?.comment ? value[0].comment : "-";
    },
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    initFilter() {
      let init = {
        byAttribute: {
          status_ancillary: 0,
          delay_days: null,
          title: null,
          code: [],
        },
        byBroker: {
          id: null,
        },
        byCollaborator: {
          id: null,
        },
        byRegistry: {
          id: null,
          null: null,
        },
        byRiskType: {
          id: null,
        },
        byRegistryGroup: {
          code: null,
        },
        byInsurer: {
          id: null,
        },
        byInsurancePolicy: {
          id: null,
          number: null,
          status_policy: null,
        },
        byInsurancePayment: {
          comment: null,
        },
        byStocastico: {
          date: moment().format("YYYY-MM-DD"),
          status: [],
        },
        byCalendar: {
          from: null,
          to: null,
        },
      };
      return init;
    },
    onAddBookEntry(row) {
      this.resetPolicyId();
      this.resetRegistryId();
      this.resetGroupId();
      this.removeQuickSearchByName(this.filterName);
      this.$router.push({
        name: `book_entries.create`,
        params: {
          mode: "IV",
          titleId: row.id,
          insuranceRiskId: row.insurance_risk_id,
        },
      });
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create` });
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${id}` },
      });
    },
    onDestroy(id) {
      // console.debug("DESTROYING id:", id);
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          // console.debug("successfully deleted!");
          this.$refs[this.tableRef].fetch();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      // console.log(name, "test");
      let criteria = this.filter;
      // story-681
      if (!this.filter.byAttribute.code.length) {
        this.filter.byAttribute.code = "!X";
      }
      // console.log("Filter: ", this.filter);
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      // console.log(this.$refs[this.tableRef]);
      // this.$refs[this.tableRef].fetch();
      // TODO
      // Trovare il punto dove viene eliminato il filter al cambio modulo e aggiungerci l'eliminazione del quicksearch
      // quicksearch
      criteria = {};

      ["quickSearchPolicy", "quickSearchRegistry", "quickSearchGroup"].forEach(
        (element) => {
          criteria[element] = {
            label: "",
            formLabel: "",
            data: null,
            formData: null,
          };
        }
      );
      criteria.quickSearchPolicy.label = this.form.inpt_label_policy;
      criteria.quickSearchPolicy.formLabel = "form.inpt_label_policy";
      criteria.quickSearchPolicy.data = this.insurance_policy_data;
      criteria.quickSearchPolicy.formData = "insurance_policy_data";
      criteria.quickSearchRegistry.label = this.form.inpt_label_registry;
      criteria.quickSearchRegistry.formLabel = "form.inpt_label_registry";
      criteria.quickSearchRegistry.data = this.registry_data;
      criteria.quickSearchRegistry.formData = "registry_data";
      criteria.quickSearchGroup.label = this.form.inpt_label_group;
      criteria.quickSearchGroup.formLabel = "form.inpt_label_group";
      criteria.quickSearchGroup.data = this.group_data;
      criteria.quickSearchGroup.formData = "group_data";
      this.saveQuickSearchByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      // REVIEW
      // NOTE
      // per tutte le index con quickSearch
      // finché non si trova il modo di "fillare" anche i dati delle quicksearch memorizzati nel criteria,
      // non è possibile consentire alla index di far autofecth, poichè non si vede nelle quicksearch che viene applicato
      // anche quel filtro!!! perciò, eliminiamo subito il filtro.
      // this.$refs[this.tableRef].fetch().then(() => {
      //   this.removeFilterByName(name);
      //   this.removeQuickSearchByName(name);
      // });
      this.$refs[this.tableRef].fetch().then(() => {
        // story-681
        if (this.filter.byAttribute.code === "!X") {
          this.filter.byAttribute.code = [];
        }
      });
    },
    onClearFilter(name) {
      this.resetPolicyId();
      this.resetRegistryId();
      this.resetGroupId();
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removeQuickSearchByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "delay_until";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    handleRegistryModalInput(value) {
      this.filter.byRegistry.id = value.id;
      this.registry_data = value;
      this.form.inpt_label_registry = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    openQuickSearchRegistry() {
      this.filter.byRegistry.id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    resetRegistryId() {
      this.registry_data = null;
      this.form.inpt_label_registry = null;
      this.filter.byRegistry.id = null;
    },
    handlePolicyModalInput(value) {
      this.form.quick_value = value.id;
      this.filter.byInsurancePolicy.id = value.id;
      this.form.inpt_label_policy = value.number;
      this.insurance_policy_data = value;
      this.$bvModal.hide("quickSearchPolicyModal");
    },
    openQuickSearchPolicy() {
      this.form.quick_value = null;
      this.$bvModal.show("quickSearchPolicyModal");
    },
    resetPolicyId() {
      this.insurance_policy_data = null;
      this.form.inpt_label_policy = null;
      this.filter.byInsurancePolicy.id = null;
    },
    handleGroupModalInput(value) {
      this.filter.byRegistryGroup.code = value.code;
      this.group_data = value;
      this.form.inpt_label_group = this.toInfoData(
        this.group_data,
        "registry_group"
      );
      this.$bvModal.hide("quickSearchGroupModal");
    },
    openQuickSearchGroup() {
      this.filter.byRegistryGroup.code = null;
      this.$bvModal.show("quickSearchGroupModal");
    },
    resetGroupId() {
      this.group_data = null;
      this.form.inpt_label_group = null;
      this.filter.byRegistryGroup.code = null;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("quicksearch", {
      saveQuickSearchByName: "saveByName",
      removeQuickSearchByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getSalesmen: "salesmen",
      getCooperators: "cooperators",
      getRisks: "risks",
    }),
  },
  computed: {
    agencyHasFee() {
      return this.$store.state.auth.use_fee === "Y" ? true : false;
    },
  },
  // beforeMount() {
  // // #1153 se non c'è già un filtro salvato, imposto i valori di default
  // let found = this.loadFilter(this.filterName);
  // if (!found) {
  //   this.$set(this, "filter", this.initFilter());
  // } else {
  //   this.$set(this, "filter", found);
  // }
  // },
  mounted() {
    this.companies = this.getInsurers();
    this.salesmen = this.getSalesmen();
    this.risks = this.getRisks();
  },
  created() {
    if (this.agencyHasFee) {
      const feeGrossField = {
        key: "fee_gross",
        label: this.getDictionary("fee_gross", "insurance_ancillary"),
        formatter: (value) => toLocaleCurrency(value),
        sortable: true,
        thClass: "text-right",
        thStyle: {
          "text-align": "right",
        },
        tdClass: "text-right",
      };
      this.fields.splice(11, 0, feeGrossField);
    }
    /* */
    const path = "auth/";
    const resources = [];
    /* Commented out because of the import of quickSearchRegistry */
    // if (!this.hasLoadedResource("councils"))
    //   resources.push(
    //     this.$store
    //       .dispatch(`${path}councils`)
    //       .then(() => {
    //         console.info(`background data "${path}councils" fetched correctly`);
    //       })
    //       .catch((error) => {
    //         let errMsg = this.$getErrorMessage(error);
    //         this.$showSnackbar({ preset: "error", text: errMsg });
    //       })
    //   );
    // if (!this.hasLoadedResource("provinces"))
    //   resources.push(
    //     this.$store
    //       .dispatch(`${path}provinces`)
    //       .then(() => {
    //         console.info(
    //           `background data "${path}provinces" fetched correctly`
    //         );
    //       })
    //       .catch((error) => {
    //         let errMsg = this.$getErrorMessage(error);
    //         this.$showSnackbar({ preset: "error", text: errMsg });
    //       })
    //   );
    // if (!this.hasLoadedResource("countries")) {
    //   resources.push(
    //     this.$store
    //       .dispatch(`${path}countries`)
    //       .then(() => {
    //         console.info(
    //           `background data "${path}countries" fetched correctly`
    //         );
    //       })
    //       .catch((error) => {
    //         let errMsg = this.$getErrorMessage(error);
    //         this.$showSnackbar({ preset: "error", text: errMsg });
    //       })
    //   );
    // }
    if (!this.hasLoadedResource("cooperators")) {
      resources.push(
        this.$store
          .dispatch(`${path}cooperators`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.cooperators = this.getCooperators();
    }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          this.cooperators = this.getCooperators();
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
  },
  // beforeDestroy() {
  //   this.onClearFilter(this.filterName);
  // },
};
</script>

<style lang="scss" scoped>
.label {
  margin-left: 5px;
  margin-right: 5px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.ending_colour {
  width: 15px;
  height: 15px;
  background: #fff8bb;
  margin-left: 18px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.last_day_colour {
  width: 15px;
  height: 15px;
  background: #ffa91f;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.expired_colour {
  width: 15px;
  height: 15px;
  background: #ffdddd;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
</style>
